<template>
  <v-container>
    <v-card>
      <v-card-title>
        <NavButton class="mb-2 mb-sm-0" />
        <h2 class="mb-0 ml-2" style="word-break: break-word">
          Productos de la orden de compra
        </h2>
      </v-card-title>
      <v-card-actions class="px-4 py-4">
        <v-btn @click="fetchHistory" color="primary">Ver historial</v-btn>
      </v-card-actions>
      <v-card class="px-4 pb-4" style="margin-left: 20px; margin-right: 20px">
        <v-card-title class="d-flex align-center">
          <v-icon>mdi-gesture-tap</v-icon>
          <h5 class="mb-0 ml-2" style="word-break: break-word">
            Acciones rápidas.
          </h5>
        </v-card-title>

        <!-- <div class="mt-4 mx-auto mb-4"> -->
        <EmptyComponent :empty="sinAccionesRapidas" colored>
          <template v-slot:empty-content>
            <v-icon x-large>mdi-hand-back-right-off-outline</v-icon>
            <p class="mb-0 mt-2 font-weight-bold text-body-1">
              No se encuentran acciones rápidas.
            </p>
          </template>
          <div class="d-flex flex-wrap mt-2" style="gap: 10px">
            <v-btn
              v-for="(boton, index) in botones.data"
              :key="index"
              color="primary"
              small
              @click="abrirAcciones(boton)"
            >
              {{ boton.accion_requerida_plural }}
            </v-btn>
          </div>
        </EmptyComponent>
      </v-card>
      <v-card-text>
        <DataTableComponent
          :headers="headers"
          :items="cartProducts"
          :tiene_paginacion="true"
        >
          <template v-slot:header.estado="{ header }">
            <span
              @click.stop="isStatusesModalOpen = true"
              style="cursor: pointer"
            >
              {{ header.text }}
              <v-icon
                color="#FFFFFF !important"
                style="font-size: 18px !important; margin-left: 2px"
              >
                mdi mdi-help-circle-outline
              </v-icon>
            </span>
          </template>
          <template v-slot:item.estado="{ item }">
            {{ item.proSolCom_bitProSol.sec_bit.es_sec.nombre }}
          </template>
          <template v-slot:item.acciones="{ item }">
            <v-btn @click="navegarDetalle(item.id)">
              <v-icon>mdi-eye-circle</v-icon> Ver detalle
            </v-btn>
          </template>
          <template v-slot:item.atributos="{ item }">
            <v-chip
              v-for="(atributo, index) in item.CMOfertaProductoConvenio
                .ofeProCon_atrOfe"
              :key="index"
              class="ma-2 text--secondary"
            >
              {{ atributo.nombre }} : {{ atributo.valor }}
              {{ atributo.unidad ?? "" }}
            </v-chip>
          </template>
          <template v-slot:item.adicional="{ item }">
            <span v-if="validacionCamposAdicionales(item)">
              {{ validacionMonto ?? item.monto ? `Monto: ${item.monto}` : ``}}
              {{ item.altura ? `Altura: ${item.altura}` : null }}
              <br />
              {{ item.base ? `Base: ${item.base}` : null }}
            </span> 
            <span v-else>-</span>
          </template>
        </DataTableComponent>
      </v-card-text>
    </v-card>
    <RequestDetailsModal
      :is-open="isDetailsOpen"
      :request="activeCartItem"
      @on-close="toggleDetailsModal(null, false)"
    />
    <DenyModalComponent
      :isOpen="isDenyModalOpen"
      ctaText="Denegar"
      @on-cancel="toggleDenyModal(null, false)"
      @on-save="handleDenySave"
    />
    <ConfirmationModalComponent
      :isOpen="confirmationModal.isOpen"
      :description="confirmationModal.description"
      @confirm="handleConfirmation"
      @cancel="clearConfirmation"
    />
    <v-dialog v-model="dialog" scrollable>
      <app-time-line :items="bitacoras" @cerrar="dialog = false">
        <template v-slot:suffix="{ item }">
          <v-btn
            v-if="item.adjunto"
            small
            class="ml-1"
            color="primary"
            @click.stop="loadAttachment(item.adjunto)"
          >
            <v-icon class="mr-1" color="#FFFFFF !important"
              >mdi mdi-attachment</v-icon
            >
            Ver
          </v-btn>
        </template>
      </app-time-line>
    </v-dialog>
    <CMEstadosModal
      :isOpen="isStatusesModalOpen"
      :statuses="statuses.data"
      @on-visibility-change="handleStatusesModal"
    />
    <PdfModal
      :isOpen="isPdfModalOpen"
      :source-loadable="pdfLoadable"
      @on-visibility-change="handlePdfModalVisibility"
      :filename="filename"
    />
    <v-dialog v-model="isFormModalOpen" max-width="600px">
      <component
        v-if="activeStep"
        :is="`${activeStep.item.formulario}-form`"
        @on-save="guardar"
        @on-cancel="isFormModalOpen = false"
      />
    </v-dialog>
    <ConfirmationModalComponent
      :isOpen="confirmationModal.isOpen"
      :description="confirmationModal.description"
      :isLoading="alterar.isLoading"
      @confirm="alterarEstado"
      @cancel="clearConfirmation"
    />
  </v-container>
</template>
<script>
import RequestDetailsModal from "./components/RequestDetailsModal.vue";
import DataTableComponent from "@/components/DataTableComponent.vue";
import {
  createLoadable,
  toggleLoadable,
  setLoadableResponse,
  isResponseSuccesful,
} from "@/utils/loadable.js";
import {
  ConfirmationModalComponent,
  DenyModalComponent,
  NavButton,
} from "@/components/utils";
import CMEstadosModal from "@/components/CMEstadosModal.vue";
import PdfModal from "@/components/PdfModal.vue";
import { formatDate } from "@/utils/datetime";
import { mapMutations } from "vuex";
import { convertToFormData, getFilenameFromPath, removePropsFromObjectIfNil } from "@/utils/data";
import { EmptyComponent, SpinnerComponent } from "@/components/utils";
import {
  AdjuntoForm,
  CantidadAdjuntoForm,
  CantidadForm,
  ComentarioForm,
  FechaForm,
  ComentarioAdjuntoForm,
  FechaAdjuntoForm,
} from "@/components/CMForms";

const confirmationActions = {
  "mark-as-received": "mark-as-received",
  "mark-as-denied": "mark-as-denied",
};

const initialConfirmationModal = {
  isOpen: false,
  description: "",
  action: "",
  data: null,
};

export default {
  components: {
    DataTableComponent,
    ConfirmationModalComponent,
    RequestDetailsModal,
    DenyModalComponent,
    CMEstadosModal,
    PdfModal,
    NavButton,
    EmptyComponent,
    AdjuntoForm,
    CantidadAdjuntoForm,
    CantidadForm,
    ComentarioForm,
    FechaForm,
    ComentarioAdjuntoForm,
    FechaAdjuntoForm,
  },
  data: () => ({
    history: createLoadable([]),
    cart: createLoadable(null),
    statuses: createLoadable([]),
    isStatusesModalOpen: false,
    // UI
    activeCartItem: null,
    isDenyModalOpen: false,
    dialog: false,
    isDetailsOpen: false,
    confirmationModal: initialConfirmationModal,
    // PDF
    isPdfModalOpen: false,
    pdfLoadable: createLoadable(null),
    filename: "",
    activePath: "",
    botones: createLoadable([]),
    alterar: createLoadable([]),
    dataGuardada: null,
    isFormModalOpen: false,
    activeStep: null,
  }),
  methods: {
    ...mapMutations("catalogoEnLinea", ["setFeedbackModalConfig"]),
    clearConfirmation() {
      this.confirmationModal = initialConfirmationModal;
    },
    navegarDetalle(id) {
      this.$router.push({
        name: "cm-detalle-item-solicitud-proveedor",
        params: { id },
      });
    },
    toggleDetailsModal(cartItem, open) {
      this.activeCartItem = cartItem;
      this.isDetailsOpen = open;
    },
    openReceivedModal(cartItem) {
      this.activeCartItem = cartItem;
      this.confirmationModal = {
        isOpen: true,
        description: "¿Desea marcar esta solicitud como recibida?",
        action: confirmationActions["mark-as-received"],
        data: cartItem,
      };
    },
    toggleDenyModal(cartItem, open) {
      this.activeCartItem = cartItem;
      this.isDenyModalOpen = open;
    },
    handleStatusesModal(visible) {
      this.isStatusesModalOpen = visible;
    },
    handleConfirmation() {
      this.setFeedbackModalConfig({
        isOpen: true,
        description: this.feedbackMessage,
        type: "success",
        onClose: this.clearConfirmation(),
      });
    },
    // Data
    handleDenySave(data) {
      this.confirmationModal = {
        isOpen: true,
        description: "¿Desea continuar con la denegación de esta solicitud?",
        action: confirmationActions["mark-as-denied"],
        data,
      };
    },
    async fetchHistory() {
      toggleLoadable(this.history);

      const { data } =
        await this.services.cmAprobacionSolicitudCompra.historial(
          this.$route.params.id
        );
      setLoadableResponse(this.history, data);

      if (!isResponseSuccesful(data)) return;
      this.dialog = true;
    },
    async fetchStatuses() {
      toggleLoadable(this.statuses);
      const { data } = await this.services.cmSupplierApproval.getStatusList();

      setLoadableResponse(this.statuses, data);
    },
    async fetchCart() {
      toggleLoadable(this.cart);

      const { data } =
        await this.services.cmAprobacionSolicitudCompra.detalleSolicitudAprobada(
          this.$route.params.id,
          { id_orden: this.$route.params.id_orden }
        );
      setLoadableResponse(this.cart, data);
    },
    handlePdfModalVisibility(visible) {
      this.isPdfModalOpen = visible;
    },
    async loadAttachment(path) {
      this.isPdfModalOpen = true;
      if (this.activePath === path) return;

      this.filename = getFilenameFromPath(path);
      this.activePath = path;
      toggleLoadable(this.pdfLoadable);
      const { data } =
        await this.services.cmAprobacionSolicitudCompra.loadAttachment({
          nombre: path,
        });

      setLoadableResponse(this.pdfLoadable, data, { isFile: true });
    },
    abrirAcciones(item, msg) {
      this.isFormModalOpen = false;
      if (item.formulario) {
        this.confirmationModal = {
          description:
            msg ?? `¿Desea ${item.accion_requerida_plural} esta solicitud?`,
          data: removePropsFromObjectIfNil(item, ["comentario", "adjunto"]),
          item: item,
        };
        this.isFormModalOpen = true;
        this.activeStep = this.confirmationModal;
      } else {
        this.confirmationModal = {
          isOpen: true,
          description:
            msg ?? `¿Desea ${item.accion_requerida_plural} esta solicitud?`,
          data: removePropsFromObjectIfNil(item, ["comentario", "adjunto"]),
          item: item,
        };
        this.activeStep = this.confirmationModal;
      }
    },

    async listarBotones() {
      toggleLoadable(this.botones);
      const { data } =
        await this.services.cmOrdenesCompra.obtenerBotonesOrdenCompra(
          this.$route.params.id_orden
        );
      setLoadableResponse(this.botones, data);
    },

    async alterarEstado() {
      toggleLoadable(this.alterar);
      const formData = convertToFormData({
        ...this.dataGuardada,
        id_secuencia: this.activeStep.item.id_secuencia,
      });
      const { data } = await this.services.cmOrdenesCompra.alterarEstadoCompra(
        this.$route.params.id_orden,
        formData
      );
      setLoadableResponse(this.alterar, data);
      if (isResponseSuccesful(data)) {
        const filters = {
          per_page: 10,
          page: 1,
        };
        this.confirmationModal.isOpen = false;
        this.fetchCart();
        this.listarBotones();
      }
    },
    async guardar(data) {
      this.isFormModalOpen = false;
      this.confirmationModal.isOpen = true;
      this.dataGuardada = data;
    },
    validacionCamposAdicionales(item) {
      const subGrupo = item?.CMOfertaProductoConvenio?.CMProducto?.CmSubGrupo;
      if (!subGrupo) return false;

      const manejoAltura = subGrupo.altura || subGrupo.altura_decimal;
      const manejoBase = subGrupo.base || subGrupo.base_decimal;
      
      return subGrupo.monto || manejoAltura || manejoBase;
    },
  },
  computed: {
    sinAccionesRapidas() {
      return !this.botones.isLoading && this.botones.data.length === 0;
    },
    cartProducts() {
      return this.cart.data?.productosActualizados ?? [];
    },
    validacionMonto() {
      return this.cartProducts.some(product => {
        const subGrupo = product?.CMOfertaProductoConvenio?.CMProducto?.CmSubGrupo;
        return subGrupo?.monto;
      });
    },
    bitacoras() {
      const bitacoras = this.history.data?.bitacoras ?? [];

      return bitacoras.map(
        ({ descripcion, fecha, comentario, bitSolCom_seq, adjunto }) => ({
          descripcion,
          fecha: formatDate(fecha),
          comentario,
          color: bitSolCom_seq.retroceso_en_flujo ? "red" : "success",
          adjunto,
        })
      );
    },
    actions() {
      return [
        {
          icon: "mdi-eye-circle",
          label: "Ver detalles",
          handler: this.toggleDetailsModal,
        },
        {
          icon: "mdi-check-circle",
          label: "Marcar como recibido",
          handler: this.openReceivedModal,
        },
        {
          icon: "mdi-close-circle",
          label: "Crear inconformidad",
          handler: this.toggleDenyModal,
        },
      ];
    },
    feedbackMessage() {
      switch (this.confirmationModal.action) {
        case confirmationActions["mark-as-denied"]:
          return "Inconformidad creada correctamente";
        case confirmationActions["mark-as-received"]:
          return "Solicitud marcada como recibida correctamente";
        default:
          return "";
      }
    },

    headers() {
      const headers = [
        { align: 'center', sortable: false, value: 'CMOfertaProductoConvenio.CMProducto.nombre', text: 'Producto' },
        { align: 'center', sortable: false, value: 'CMOfertaProductoConvenio.CMConvenioMarco.Proveedor.nombre', text: 'Proveedor' },
        ...(!this.validacionMonto ? [{ align: 'center', sortable: false, text: 'Precio', value: 'precio_de_oferta' }] : []),
        ...(this.validacionCamposAdicionales ? [{ align: 'center', sortable: false, text: 'Información adicional', value: 'adicional' }] : []),
        { align: 'center', sortable: false, value: 'cantidad', text: 'Cantidad' },
        { align: 'center', sortable: false, value: 'subtotal', text: 'Subtotal' },
        { align: 'center', sortable: false, value: 'estado', text: 'Estado' },
        { align: 'center', sortable: false, value: 'acciones', text: 'Acciones' },
      ];
      return headers;
    }
  },
  created() {
    this.listarBotones();
    this.fetchCart();
    this.fetchStatuses();
  },
};
</script>
