<template>
    <v-dialog v-model="internalOpen" max-width="600px">
        <div
            class="py-4 px-4" 
            style="background-color: #FFFFFF;"
        >
            {{ request?.producto }}
            <div class="mt-8 d-flex justify-center">
                <v-btn color="primary" @click.stop="handleClose">Volver</v-btn>
            </div>
        </div>
    </v-dialog>
</template>
<script>
import { isNil } from 'lodash';

export default {
name: 'RequestDetailsModal',
emits: ['on-close'],
props: {
    isOpen: { type: Boolean, },
    request: { type: Object, },
},
data: () => ({
    internalOpen: false,
}),
methods: {
    handleClose() {
        this.internalOpen = false;
    },
},
watch: {
    internalOpen(value) {
        if (value) return;
        
        this.$emit('on-close', value);
    },
    isOpen(value) {
        if (isNil(value)) return;

        this.internalOpen = value;
    },
},
}
</script>