<template>
    <v-dialog v-model="internalOpen" max-width="700">
        <div style="background-color: #FFFFFF; padding: 0 1em 2em; position: relative;">
            <div class="py-4" style="width: 100%; background-color: inherit; position: sticky; top: 0; z-index: 10;">
                <v-btn 
                    style="min-width: unset !important; min-height: unset !important; width: 32px !important; height: 32px !important;" 
                    class="px-4 py-4"
                    @click.stop="internalOpen = false" 
                    text
                    rounded
                >
                    <v-icon>mdi-window-close</v-icon>
                </v-btn>
            </div>
            <div class="px-1 mt-1">    
                <v-text-field
                    label="Estado"
                    placeholder="Buscar estado"
                    outlined
                    clearable
                    prepend-inner-icon="mdi mdi-magnify"
                    @input="debouncedSearch"
                />
                <v-expansion-panels>
                    <v-expansion-panel
                        v-for="(status) in filteredStatuses"
                        :key="status.id"
                    >
                    <v-expansion-panel-header>
                        {{ status.nombre }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        {{ status.descripcion }}
                    </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </div>
        </div>
    </v-dialog>
</template>
<script>
import { isNil, debounce } from 'lodash';

export default {
    name: 'CMEstadosModal',
    emits: ['on-visibility-change'],
    props: {
        isOpen: { type: Boolean },
        statuses: { type: Array, required: true },
    },
    data() {
        return {
            internalOpen: false,
            term: '',
        };
    },
    computed: {
        filteredStatuses() {
            return this.$props.statuses.filter((status) => status.nombre.toLowerCase().includes(this.term.toLowerCase()));
        },
    },
    methods: {
        searchStatus(term) {
            this.term = term;
        },
    },
    watch: {
        isOpen(value) {
            if (isNil(value)) return;

            this.internalOpen = value;
        },
        internalOpen(value) {
            this.$emit('on-visibility-change', value);
        },
    },
    created() {
        this.debouncedSearch = debounce(this.searchStatus, 200);
    },
}
</script>